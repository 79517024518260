import React from 'react'
import PropTypes from 'prop-types'
import { ScrollBlock } from './index.style'

const ScrollSection = ({ children, ...props }) => (
  <ScrollBlock data-scroll-section {...props}>
    {children}
  </ScrollBlock>
)

ScrollSection.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ScrollSection
