import React, { useRef, useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import Player from '@vimeo/player'
import IconVideoPlayButton from '../svgs/IconVideoPlayButton'
import {
  VimeoPlayerMain,
  VimeoPlayerEmbed,
  VimeoPlayerOverlay,
  VimeoPlayButton,
  VimeoPlayerWrapper,
} from './index.style'

const VimeoPlayer = ({ id, options = {}, cover }) => {
  const wrapperRef = useRef()
  const videoEmbedRef = useRef()
  const videoPlayer = useRef()
  const [loaded, setLoaded] = useState(cover ? false : true)
  const [playing, setPlaying] = useState(
    options.autoplay || options.background ? true : false
  )
  const [videoDimensions, setVideoDimensions] = useState(null)
  const [coverWidth, setCoverWidth] = useState(null)

  const play = () => {
    videoPlayer.current
      .play()
      .then(() => {
        setPlaying(true)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useLayoutEffect(() => {
    const embedOptions = {
      id,
      ...options,
    }
    let videoDimensionsLocal

    const updateCoverDimensions = () => {
      const targetDimensions = {
        width: wrapperRef.current?.offsetWidth,
        height: wrapperRef.current?.offsetHeight,
      }
      if (videoDimensionsLocal) {
        const subPixelOffset = 2
        if (
          targetDimensions.width *
            (videoDimensionsLocal[1] / videoDimensionsLocal[0]) <
          targetDimensions.height + subPixelOffset
        ) {
          setCoverWidth(
            (targetDimensions.height + subPixelOffset) *
              (videoDimensionsLocal[0] / videoDimensionsLocal[1])
          )
        }
      }
    }

    videoPlayer.current = new Player(videoEmbedRef.current, embedOptions)

    Promise.all([
      videoPlayer.current.getVideoWidth(),
      videoPlayer.current.getVideoHeight(),
    ]).then(dimensions => {
      setVideoDimensions(dimensions)
      videoDimensionsLocal = dimensions
      updateCoverDimensions()
    })

    videoPlayer.current.on('pause', () => setPlaying(false))
    videoPlayer.current.on('ended', () => setPlaying(false))
    videoPlayer.current.on('play', () => {
      setPlaying(true)

      if (cover) {
        setLoaded(true)
      }
    })

    if (cover) {
      window.addEventListener('resize', updateCoverDimensions)
    }

    return () => {
      videoPlayer.current.off('pause')
      videoPlayer.current.off('ended')
      videoPlayer.current.off('play')

      if (cover) {
        window.removeEventListener('resize', updateCoverDimensions)
      }
    }
  }, [id, options, cover])

  return (
    <VimeoPlayerWrapper ref={wrapperRef} cover={cover} show={loaded}>
      <VimeoPlayerMain
        aspectRatio={videoDimensions && videoDimensions[1] / videoDimensions[0]}
        cover={cover}
        style={{ width: coverWidth }}
      >
        {(!options.autoplay || !options.background) && (
          <VimeoPlayerOverlay
            aria-label="Play video"
            playing={playing}
            onClick={play}
          >
            <VimeoPlayButton aria-label="Play video">
              <IconVideoPlayButton />
            </VimeoPlayButton>
          </VimeoPlayerOverlay>
        )}

        <VimeoPlayerEmbed ref={videoEmbedRef} playing={playing} />
      </VimeoPlayerMain>
    </VimeoPlayerWrapper>
  )
}

VimeoPlayer.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.object,
}

export default VimeoPlayer
