import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Container from '../components/Container'
import Grid from '../components/Grid'
import GridItem from '../components/GridItem'
import PageTransitionLink from '../components/PageTransitionLink'
import { Heading2, Heading3, TextBody } from '../components/TextStyles'
import Spacer from '../components/Spacer'
import Flow from '../components/Flow'
import ScrollSection from '../components/ScrollSection'
import AnimateSplitText from '../components/animation/AnimateSplitText'
import AnimateSlideIn from '../components/animation/AnimateSlideIn'
import Tabs from '../components/Tabs'
import { colors } from '../styles/vars/colors.style'
import VimeoPlayer from '../components/VimeoPlayer'
import Button from '../components/Button'
import IconArrowheadLeft from '../components/svgs/IconArrowheadLeft'
import IconArrowheadRight from '../components/svgs/IconArrowheadRight'
import RichText from '../components/RichText'

const StyleguidePage = ({ data }) => {
  const { seoTitle, seoDescription, slug, content } = data.contentfulStyleguide

  const dummyTabData = [
    {
      title: 'Tab 1',
      content: <TextBody>Tab 1 content</TextBody>,
    },
    {
      title: 'Tab 2',
      content: <TextBody>Tab 2 content</TextBody>,
    },
    {
      title: 'Tab 3',
      content: <TextBody>Tab 3 content</TextBody>,
    },
  ]

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} pathname={slug} />

      <ScrollSection>
        <Container>
          <Spacer size={[120, 260]} />

          <Grid>
            <GridItem tabletL={6} tabletLStart={4}>
              <Heading2 color={colors.blue}>
                <AnimateSplitText>Rich text</AnimateSplitText>
              </Heading2>

              <Spacer size={[12, 24]} />

              {content && <RichText content={content} />}

              <Spacer size={[40, 80]} />

              <Heading2 color={colors.blue}>
                <AnimateSplitText>Components</AnimateSplitText>
              </Heading2>

              <Spacer size={[12, 24]} />

              <Heading3>
                <AnimateSplitText>Buttons</AnimateSplitText>
              </Heading3>

              <Spacer size={[12, 24]} />

              <Flow direction="horizontal">
                <AnimateSlideIn>
                  <Button onClick={() => alert('A primary click')}>
                    Primary button
                  </Button>
                </AnimateSlideIn>

                <AnimateSlideIn>
                  <Button
                    variant="secondary"
                    onClick={() => alert('A secondary click')}
                  >
                    Secondary button
                  </Button>
                </AnimateSlideIn>
              </Flow>

              <Spacer size={[9, 18]} />

              <Flow direction="horizontal">
                <AnimateSlideIn>
                  <Button disabled>Disabled button</Button>
                </AnimateSlideIn>

                <AnimateSlideIn>
                  <Button
                    href="https://toyfight.co"
                    iconRight={<IconArrowheadRight />}
                  >
                    An external link
                  </Button>
                </AnimateSlideIn>
              </Flow>

              <Spacer size={[9, 18]} />

              <Flow direction="horizontal">
                <AnimateSlideIn>
                  <PageTransitionLink to="/">
                    <Button iconLeft={<IconArrowheadLeft />} inline>
                      A page navigation button
                    </Button>
                  </PageTransitionLink>
                </AnimateSlideIn>

                <AnimateSlideIn>
                  <Button label="Go back">
                    <IconArrowheadLeft responsive={false} />
                  </Button>
                </AnimateSlideIn>
              </Flow>

              <Spacer size={[12, 24]} />

              <Heading3>
                <AnimateSplitText>Tabs</AnimateSplitText>
              </Heading3>

              <Spacer size={[12, 24]} />

              <AnimateSlideIn>
                <Tabs data={dummyTabData} />
              </AnimateSlideIn>

              <Spacer size={[24, 48]} />

              <Heading3>
                <AnimateSplitText>Vimeo Player</AnimateSplitText>
              </Heading3>

              <Spacer size={[12, 24]} />

              <AnimateSlideIn>
                <VimeoPlayer id="148751763" />
              </AnimateSlideIn>
            </GridItem>
          </Grid>

          <Spacer size={[120, 360]} />
        </Container>
      </ScrollSection>
    </>
  )
}

export default StyleguidePage

export const pageQuery = graphql`
  query StyleguidePage($slug: String!) {
    contentfulStyleguide(slug: { eq: $slug }) {
      seoTitle
      seoDescription
      seoImage {
        gatsbyImageData
      }
      slug
      content {
        raw
      }
    }
  }
`
